import React from 'react'
import SocialCommunity from '../../../components/socialCommunity/SocialCommunity'

const SocialCommunitySection = () => {
  return (
    <div className='flex justify-center'>
      <SocialCommunity />
    </div>
  )
}

export default SocialCommunitySection
