import React from 'react'
import Roadmap from '../../components/roadmap/Roadmap'

const RoadmapSection = () => {
  return (
    <Roadmap/>
  )
}

export default RoadmapSection
