// RoadmapBoxSection.js
import React from "react";
import RoadmapBoxes from "../../components/roadmap/RoadmapBoxes";


const RoadmapBoxSection = () => {
 

  return (
    <div
    >
      <RoadmapBoxes />
    </div>
  );
};

export default RoadmapBoxSection;
