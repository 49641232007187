import React from "react";
import TermsNConditionSection from "../../sections/TermsNConditionSection/TermsNConditionSection";

export default function TermsNCondition() {
  return (
    <>
      <TermsNConditionSection />
    </>
  );
}
