import React from "react";
import GameSection from "../../sections/Game/GameSection";
const GamePage = () => {
  return (
    <div className="max-w-[1440px] mx-auto">
      <GameSection />
    </div>
  );
};

export default GamePage;
