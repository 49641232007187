import React from 'react'
import NotFoundSection from '../../sections/404Section/NotFoundSection'

const NotFoundPage = () => {
  return (
    <div className='max-w-[1440px] mx-auto'>
      <NotFoundSection />
    </div>
  )
}

export default NotFoundPage
