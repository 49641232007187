import React from "react";
import PrivacyPolicySection from "../../sections/PrivacyPolicySection/PrivacyPolicySection";

export default function PrivacyPolicy() {
  return (
    <>
      <PrivacyPolicySection />
    </>
  );
}
