import React from 'react'
import NftMarquee from '../../../components/nftMarquee/NftMarquee'

const MarqueeSection = () => {
  return (
    <div className='mt-20'>
      <NftMarquee />
    </div>
  )
}

export default MarqueeSection
