import React from "react";
import MintIsLive from "../../../components/mintIsLive/MintIsLive";

const MintIsLiveSection = () => {
  return (
    <div>
      <MintIsLive />
    </div>
  );
};

export default MintIsLiveSection;
